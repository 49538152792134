import { InjectionToken, WritableSignal } from '@angular/core';

export const i18nServiceToken: InjectionToken<II18nService> = new InjectionToken<II18nService>('i18nServiceToken');

export enum Country {
    Germany = 'DE'
}

export enum LanguageCode {
    EN = 'en',
    DE = 'de',
}

export interface II18nService {
    country: Country;

    currentLanguage: WritableSignal<LanguageCode>;

    setLanguage(language: string): void;

    getLanguages(): LanguageCode[];
}
